<template>
<div>
  
  <DashboardHeader></DashboardHeader>

  <v-row class="pb-5 mx-sm-0">
    <v-col cols="12">
      <v-divider class="my-3"/>
        <v-icon class="mb-1">mdi-star-outline</v-icon> <b>Commandes</b>
        <v-btn @click="loadCommandes()" elevation="0" class="ml-2"
                dark fab x-small>
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
      <v-divider class="my-3"/>

      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="blue">mdi-circle</v-icon> En cours
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="teal">mdi-circle</v-icon> Acompte payé
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="amber">mdi-circle</v-icon> Prête à envoyer
      </v-chip>
      <v-chip class="mr-2" small color="transparent">
        <v-icon small class="mr-1" color="green">mdi-circle</v-icon> Total payé
      </v-chip>

    </v-col>
    <template v-for="(commande, c) in commandes">
      <v-col cols="12" sm="6" md="4" :key="c" class="">
        <CardCommande :commande="commande"></CardCommande>
      </v-col>
    </template>
  </v-row>

</div>
</template>

<style></style>

<script>
import axios from 'axios'
//import router from '../router/router'
//import core from '../plugins/core.js'

import DashboardHeader from '@/components/admin/DashboardHeader.vue'
import CardCommande from '@/components/admin/card/CardCommande.vue'

export default {
  name: 'admin-dashboard',
  components: { DashboardHeader, CardCommande },
  data: () => ({
    commandes: [],
  }),
  async mounted(){
    this.loadCommandes()

    this.$root.$off('reloadCommandes').$on('reloadCommandes', () => { 
      this.loadCommandes()
    })

    this.$root.$off('addToChartSold')
    this.$root.$off('rebootChartSold')
    this.$root.$off('rerenderChartSold')
  },
  methods: {   
    async loadCommandes(){
      let res = await axios.post('/admin/query-entities', {
                        entityType: "commande",
                        query: { status: { '$in' : ['OPEN', 'PREPAID', 'READY', 'PAID'] }  },
                        sort: { created : -1 }
                      })
      if(res.data.error == false){
        this.commandes = res.data.entities
      }
    }
  },
  computed: {    
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
      '$route.path': { immediate: true, 
          async handler (val, oldVal) {
              if(window.isDev) { console.log("#MAIN -- route.name changed", val, oldVal) }
              //this.fetchData()
          }
      },
  }, 
}
</script>
